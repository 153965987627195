<template>
  <div>
    <h1>Users</h1>

    <div v-if="isTableLoading" class="text-center pt-3">
      <b-spinner label="Loading..." />
    </div>

    <template v-else>

      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <b-button variant="primary" class="float-right" v-b-modal.manage-modal @click="openAddModal">
            <span> Add user </span>
          </b-button>
        </b-form-group>
      </div>

      <!-- search input -->
      <!--<div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>-->

      <vue-good-table
        :columns="columns"
        :rows="users"
        :search-options="{
          enabled: true,
          //externalQuery: searchTerm
          }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
              <span>
                  <b-dropdown
                          variant="link"
                          toggle-class="text-decoration-none"
                          no-caret
                          right
                  >
                      <template v-slot:button-content>
                        <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="text-body align-middle mr-25"
                        />
                      </template>
                      <b-dropdown-item v-b-modal.manage-modal @click="openEditModal(props.row)">
                          <feather-icon icon="Edit2Icon" class="mr-50" />
                          <span>Edit</span>
                      </b-dropdown-item>

                      <b-dropdown-item @click="deleteItem(props.row.id)">
                          <feather-icon icon="TrashIcon" class="mr-50" />
                          <span>Delete</span>
                      </b-dropdown-item>
                  </b-dropdown>
              </span>
          </span>
            <span v-else-if="props.column.field === 'inactive'">
                {{props.row.inactive == 0 ? 'Active' : 'Inactive'}}
            </span>
            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>

        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-end flex-wrap">
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </template>
      </vue-good-table>

      <b-modal
        id="manage-modal"
        :title="edited.id > 0 ? 'Edit user' : 'Add user'"
      >

        <b-form>

          <b-form-group label="Name:" label-for="input-name">
            <validation-provider #default="{ errors }" name="name" rules="required">
              <b-form-input id="name" v-model="edited.name" placeholder="User Name" required />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Email:" label-for="input-email">
            <validation-provider #default="{ errors }" name="email" rules="required">
              <b-form-input id="email" v-model="edited.email" placeholder="User Email" required />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Phone:" label-for="input-phone">
            <validation-provider #default="{ errors }" name="phone" rules="required|min:12">
              <phone-mask-input id="phone" v-model="edited.phone" defaultCountry="us" inputClass="form-control" required />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Role:" label-for="input-name">
            <validation-provider #default="{ errors }" name="role_id" rules="required">
              <v-select required
                id="role_id"
                v-model="edited.roleObject"
                item-value="id"
                label="name"
                item-text="name"
                :options="adminRoles"
                :clearable="false"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group v-show="edited.roleObject != null && edited.roleObject.name == 'Captain' ? true : false" label="Boats:" label-for="input-name">
              <v-select
                id="boat_id"
                v-model="edited.boats"
                item-value="id"
                label="name"
                item-text="name"
                :options="boats"
                :clearable="false"
                :multiple="true"
              />
          </b-form-group>

          <b-form-checkbox v-model="edited.inactive" switch class="mb-1">
            Inactive User
          </b-form-checkbox>
        </b-form>

        <template #modal-footer>
          <b-button variant="outline-secondary" class="float-right" @click="$bvModal.hide('manage-modal')">
            Close
          </b-button>
          <b-button variant="primary" class="float-right" @click="edited.id > 0 ? editSubmit() : addSubmit()" :disabled="isEditLoading">
            <b-spinner v-if="isEditLoading" label="Spinning" small />
            <span v-else> Save </span>
          </b-button>
        </template>

      </b-modal>

    </template>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BDropdown,
  BDropdownItem,
  VBModal,
  BForm,
  BSpinner,
  BButton,
  BTable,
  BFormCheckbox
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import { toastSuccess, toastFailure } from "@/libs/toastification";
import {sweetWarning, sweetError} from "@/libs/sweet-alerts";
import PhoneMaskInput from  "vue-phone-mask-input";

export default {
  components: {
    BTable,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BSpinner,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    VBModal,
    BDropdown,
    BDropdownItem,
    PhoneMaskInput,
    BFormCheckbox
  },
  data() {
    return {
      isTableLoading: true,
      isEditLoading: false,
      isAddingLoading: false,
      searchTerm: '',
      adminRoles: [],
      columns: [
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Phone',
          field: 'phone',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Role',
          field: 'role',
        },
        {
          label: "Status",
          field: "inactive",
          sortable: true,
        },
        {
          label: "Action",
          field: "action",
          thClass: "text-right pr-1",
          tdClass: "text-right",
          sortable: false,
        }
      ],
      pageLength: 25,
      edited: {
        id: null,
        name: null,
        email: null,
        phone: null,
        role_id: null,
        roleObject: null,
        boats: null,
        inactive: null
      }
    }
  },
  computed: {
    ...mapGetters({
      users: 'auth/users',
      roles: 'roles/roles',
      boats: 'boats/boats',
    }),
  },
  async mounted() {
    await this.loadUsers()
      .then(() => {
          this.isTableLoading = false;
          this.loadRoles().then(()=>{
            this.adminRoles = this.adminRolesOnly();
            this.loadBoats().catch(() => { toastFailure('Something went wrong while loading the boats') })
          }).catch(() => { toastFailure('Something went wrong while loading the roles') });
        }
      ).catch(() => { toastFailure('Something went wrong while loading users.') })
  },
  methods: {
    ...mapActions({
      loadUsers: 'auth/loadUsers',
      createAdminUser: 'auth/createAdminUser',
      updateUser: 'auth/updateUser',
      deleteUser: 'auth/deleteUser',
      loadRoles: 'roles/loadRoles',
      loadBoats: 'boats/loadBoats',
    }),
    showError(responseData=null, warning=false) {

      if( responseData != null && responseData.message != undefined && responseData.message != null ){

        let errorMSG = responseData.message + '</br>';
        if(responseData.errors){

          Object.keys(responseData.errors).map(function(errorKey){
            responseData.errors[errorKey].map(function(value, key){
              errorMSG += value + '</br>';
            });
          });
        }

        if( warning ){
          sweetWarning('Warning', errorMSG);
        }
        else{
          sweetError('Error', errorMSG);
        }
      }
      else{
        toastFailure("Something went wrong.");
      }
    },
    adminRolesOnly(){

      let adminRoles = [];
      this.roles.map(function(role){
        if( role.customer === false ){
          adminRoles.push(role);
        }
      });

      return adminRoles;
    },
    openEditModal(row) {
      this.edited.id = row.id;
      this.edited.name = row.name;
      this.edited.email = row.email;
      this.edited.phone = row.phone;
      this.edited.role_id = row.role_id;
      this.edited.boats = row.boats;
      this.edited.roleObject = this.roles.find(x => x.id === row.role_id);
      this.edited.inactive = row.inactive == 1 ? true : false;
    },
    openAddModal() {
      this.edited.id = 0;
      this.edited.name = '';
      this.edited.email = '';
      this.edited.phone = '';
      this.edited.role_id = 1;
      this.edited.boats = [];
      this.edited.roleObject = this.roles.find(x => x.id === this.edited.role_id);
    },
    async deleteItem(id) {
      if (await this.confirmationMessage('Are you sure you want to delete this user?')) {
        this.deleteTrip(id)
              .then(() => {
                toastSuccess("User has been deleted.");
              })
              .catch(() => {
                toastFailure("Something went wrong while deleting the user");
              });
      }
    },
    async addSubmit() {

      let boats = [];

      this.edited.boats.map(function(boat){
        boats.push(boat.id);
      });

      this.isAddingLoading = true;
      this.createAdminUser({
        name: this.edited.name,
        phone: this.edited.phone,
        email: this.edited.email,
        role_id: this.edited.roleObject.id,
        boats: boats
      })
      .then(() => {
        toastSuccess("New user added.");
      })
      .catch((error) => {
        this.showError(error.response.data);
      })
      .finally(() => {
        this.isAddingLoading = false;
        this.$bvModal.hide('manage-modal');
      });
    },
    async editSubmit() {

      this.isEditLoading = true;
      const formData = new FormData();

      formData.append('id', this.edited.id);
      formData.append('name', this.edited.name);
      formData.append('email', this.edited.email);
      formData.append('phone', this.edited.phone);
      formData.append('inactive', (this.edited.inactive === true ? 1 : 0));
      formData.append('role_id', this.edited.roleObject.id);

      this.edited.boats.map(function(boat){
        formData.append('boats[]', boat.id);
      });

      if( this.edited.boats !== null && this.edited.boats.length > 0 && this.edited.inactive === true ){
        let smsError = {
          message:'This user is a captain of a boat. Please change the captain of the boat before making user inactive'
        };
        this.showError(smsError, 1);
        this.isEditLoading = false;
        return false;
      }

      formData.append('_method', 'POST');
      this.updateUser(formData)
      .then(() => {
        toastSuccess("Your changes have been updated.");
      })
      .catch((error) => {
        this.showError(error.response.data);
      })
      .finally(() => {
        this.isEditLoading = false;
        this.$bvModal.hide('manage-modal');
      });
    },
  },
}
</script>
